export const UMS_ATTRIBUTES = {
  USER: {
    ACCEPTED_TERMS: 'acceptedTerms' as const,
    HOME_MODAL: 'encourage.modals.home.dismissedVersion' as const,
    STUDENT_INVITATION_DIALOG: 'encourage.modals.studentInvitation.dismissedVersion' as const,
  },
};

export interface IUMSUserAttributesMap {
  [UMS_ATTRIBUTES.USER.ACCEPTED_TERMS]: { value: boolean; at: number };
  [UMS_ATTRIBUTES.USER.HOME_MODAL]: number;
  [UMS_ATTRIBUTES.USER.STUDENT_INVITATION_DIALOG]: number;
}

export type UMSUserAttributes = Partial<IUMSUserAttributesMap>;
