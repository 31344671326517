import { createContext } from 'react';

import getEnvironment from '~/helpers/getEnvironment';

export type Flag = 'DISTRICT_SUPERINTENDENT_USER_EXPERIENCE';

export interface IDebugFlag {
  enabled: boolean;
  loading: boolean;
}

export interface IDebugContextData {
  superintendentFlag: IDebugFlag;
  setFlag?: (flag: Flag, value: boolean) => void;
}

export const DebugFlagContext = createContext<IDebugContextData>({
  superintendentFlag: { enabled: getEnvironment() !== 'production', loading: true },
} as IDebugContextData);
