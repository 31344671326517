import { MENTORING_MODULE_KEY, USERS_REPORT_MODULE } from '~/constants/ums';

import { operations } from '../constants/__generatedSwaggerTypes';

import { PermissionModule, UID, UMSUserType } from './ums';

export type DetailedPermissions = operations['getOrgPermissions']['responses']['200']['content']['application/json'];

export type DetailedPermission = DetailedPermissions[number] & {
  permissions: DetailedPermissions[number]['permissions'] & {
    [MENTORING_MODULE_KEY]?: boolean;
    [USERS_REPORT_MODULE]?: boolean;
  };
};

export type GenericPermissions = operations['getGlobalPermissions']['responses']['200']['content']['application/json'];

interface IApplication {
  uid: string;
  key: string;
  name: string;
  url: string;
  adminUrl: string;
  creator: IMiniUser;
  updatedAt: Date;
  createdAt: Date;
  modules: GenericPermissions;
  attributes: IAttribute[];
}

interface IAttribute {
  key: string;
  value?: unknown;
}

interface IOrganizationRole {
  city: string;
  externalId: string;
  hs_id: string;
  name: string;
  role: string;
  state: string;
  uid: UID;
}

interface IMiniUser {
  firstName: string;
  lastName: string;
  uid: UID;
}

interface IBaseUser extends IMiniUser {
  email: string;
  type: UMSUserType;
  userName: string;
}

export interface IActiveUser extends IBaseUser {
  exp: number;
  expiresIn: string;
  iat: number;
  rootUser?: IActiveUser;
  iss: string;
  jti: string;
  sessionUid: UID;
}

export interface IUser extends IBaseUser {
  application: IApplication;
  attributes?: IAttribute[];
  createdAt: Date;
  creator: IMiniUser;
  organizations?: { organization: IOrganizationRole; permissions: Record<string, boolean> }[];
  roles: unknown[];
  updatedAt: Date;
  sensitiveAttributes: string[];
  status: string;
  verifiedDate: Date;
  versionWarning: boolean;
}

export interface IImpersonating {
  sessionToken: string;
  user: IUser;
}

export interface IPermissioned {
  detailedPermissions?: DetailedPermissions;
  genericPermissions?: GenericPermissions;
  module?: PermissionModule;
  schoolId?: string;
  schoolSpecific?: boolean;
}

export enum BuildEnv {
  Local = 'local',
  Feature = 'feature',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}
