/* eslint-disable regex/invalid-warn */
/* eslint-disable regex/invalid-error */
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Alert, Box, Divider, Drawer, FormControlLabel, OutlinedInput, Radio, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Grid2 from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { useStudentBasicInfoQuery } from '~/__generated__/graphql';
import ENV from '~/constants/env';
import { DISTRICT_SUPERINTENDENT_USER_EXPERIENCE } from '~/constants/featureFlags';
import { ROUTES, ROUTES_REGEX } from '~/constants/routes';
import { StudentBaiscInfoQueryType } from '~/graphql/types/gqlTypes';
import getEnvironment from '~/helpers/getEnvironment';
import getIcon from '~/helpers/getIcon';
import useActiveUser from '~/hooks/useActiveUser';
import useAlert from '~/hooks/useAlert';
import useDebugMenu from '~/hooks/useDebugMenu';
import useEducatorInviteCode from '~/hooks/useEducatorInviteCode';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import useSchoolId from '~/hooks/useSchoolId';
import useSetFeatureFlag from '~/hooks/useSetFeatureFlag';
import useToken from '~/hooks/useToken';

const BugIcon = getIcon('general.debug');
export const environmentLocalCacheKey = 'debugMenuEnvironmentOverride';

const DebugMenu = (): ReactNode => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { showErrorAlert, showSuccessAlert } = useAlert();
  const router = useRouter();
  const me = useActiveUser();

  const [tabValue, setTabValue] = React.useState('1');

  const handleChangeTab = (_: React.SyntheticEvent, newValue: string): void => {
    setTabValue(newValue);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleCopyToClipboard = (itemName: string, value: string): void => {
    if (!value) {
      showErrorAlert(`Nothing to copy: ${itemName}`);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(value || '');
    showSuccessAlert(`Copied { ${itemName} } to clipboard.`);
  };

  const schoolId = useSchoolId();
  const educatorContactId = useEducatorInviteCode(schoolId);

  const contactId = educatorContactId.data?.getEducatorData && (educatorContactId.data?.getEducatorData.contact_id as string);
  const educatorInviteCode =
    educatorContactId.data?.getEducatorData && educatorContactId.data?.getEducatorData.invite_codes?.find(ic => ic.school_id === schoolId)?.invite_code;

  const user = useActiveUser();
  const uid = user?.uid;
  const token = useToken();
  const auth = `JWT ${token}`;
  const authDisplay = token ? `${auth.slice(0, 35)}...` : 'not logged in';

  const isImpersonating = !!user?.rootUser;
  const rootUser = user?.rootUser;

  const isStudentDetailsPage = !!router.asPath.match(ROUTES_REGEX.MY_STUDENTS_DETAILS);
  const studentId = String(router.query.studentId ?? '');
  const studentIdNumber = parseInt(studentId, 10);

  const { data: studentProfile } = useStudentBasicInfoQuery({
    context: { apiName: 'federatedGraph' },
    skip: !educatorInviteCode || !studentIdNumber,
    variables: {
      input: {
        contactId,
        inviteCode: educatorInviteCode,
        studentKey: studentIdNumber,
      },
    },
  });

  const typedStudentInfo = studentProfile?.encourageEducatorStudentProfile as StudentBaiscInfoQueryType;
  const studentInfo = typedStudentInfo?.studentInfo;
  const [backendUri, setBackendUri] = React.useState(process.env.DEBUG_API_ENDPOINT_GRAPHQL || ENV.API_BASE_URL_FEDERATED_GRAPH || '');
  const [selectedEnvironment, setSelectedEnvironment] = React.useState(localStorage.getItem(environmentLocalCacheKey) || ENV.BUILD_ENV || '');

  const {
    superintendentFlag: { enabled: showSuperintendentUx },
    setFlag: setSuperintendentUx,
  } = useDebugMenu();
  const { data: superintendentFeatureFlagEnabled, loading: loadingFeatureFlag } = useFeatureFlags()(DISTRICT_SUPERINTENDENT_USER_EXPERIENCE);
  const [featureFlagShowSuperintendent, setFeatureFlagShowSuperintendent] = useState(false);

  const [setFeatureFlag] = useSetFeatureFlag();

  const textInput = React.useRef<string | null>(null);
  const open = Boolean(anchorEl);
  const isDisabled = ![ROUTES.LOGIN].includes(router.pathname);
  const id = open ? 'simple-popover' : undefined;

  const showFeatureFlags = useMemo(() => !loadingFeatureFlag && me?.type === 'LdapUser', [loadingFeatureFlag, me?.type]);

  useEffect(() => {
    if (loadingFeatureFlag) return;
    setFeatureFlagShowSuperintendent(superintendentFeatureFlagEnabled);
  }, [loadingFeatureFlag, superintendentFeatureFlagEnabled]);

  const onChangeFeatureFlag = useCallback(
    (featureFlag: string, value: boolean) => {
      if (me?.type !== 'LdapUser') return;

      // eslint-disable-next-line no-void
      void setFeatureFlag({
        variables: {
          input: {
            featureFlag,
            value,
          },
        },
      });
    },
    [me?.type, setFeatureFlag],
  );

  const onChangeSuperintendentLocalFlag = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (setSuperintendentUx) setSuperintendentUx(DISTRICT_SUPERINTENDENT_USER_EXPERIENCE, checked);
    },
    [setSuperintendentUx],
  );

  const onChangeSuperintendentFeatureFlag = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChangeFeatureFlag(DISTRICT_SUPERINTENDENT_USER_EXPERIENCE, checked);
      setFeatureFlagShowSuperintendent(checked);
    },
    [onChangeFeatureFlag],
  );

  return (
    <>
      <Drawer anchor="right" id={id} onClose={handleClose} open={open} PaperProps={{ sx: { maxWidth: '500px', padding: 2, width: 'min(500px, 100vw)' } }}>
        <Button onClick={handleClose} variant="text">
          Close
        </Button>
        <TabContext value={tabValue}>
          <TabList onChange={handleChangeTab}>
            <Tab label="Info" value="1" />
            <Tab label="More" value="2" />
            <Tab label="EIS" value="3" />
          </TabList>
          <TabPanel sx={{ height: '100%' }} value="1">
            <Stack justifyContent="space-between" spacing={2} sx={{ height: '100%', minWidth: [0, 400] }}>
              <Stack p={2}>
                <Typography variant="h5">Debug menu</Typography>
                <div>
                  <Typography variant="h6">Current Federated Graph URL:</Typography>
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <Typography variant="body2">{backendUri}</Typography>{' '}
                    <Button
                      onClick={() => {
                        handleCopyToClipboard('backendUri', backendUri);
                      }}
                      sx={{ fontSize: 10, minWidth: 0, px: 1, py: 0 }}
                    >
                      Copy
                    </Button>
                  </Stack>
                </div>
                {isDisabled ? (
                  <Alert severity="warning">
                    Can only edit URL on <strong>/login</strong>
                  </Alert>
                ) : (
                  <>
                    <Stack>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={backendUri === 'https://encoura-graph-dev.enc-np.com/graphql'}
                            onChange={() => {
                              process.env.DEBUG_API_ENDPOINT_GRAPHQL = 'https://encoura-graph-dev.enc-np.com/graphql';
                              setBackendUri('https://encoura-graph-dev.enc-np.com/graphql');
                            }}
                          />
                        }
                        label="Dev"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={backendUri === 'https://encoura-graph-stg.enc-np.com/graphql'}
                            onChange={() => {
                              process.env.DEBUG_API_ENDPOINT_GRAPHQL = 'https://encoura-graph-stg.enc-np.com/graphql';
                              setBackendUri('https://encoura-graph-stg.enc-np.com/graphql');
                            }}
                          />
                        }
                        label="Stage"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={backendUri === 'https://encoura-graph.myoptions.org/graphql'}
                            onChange={() => {
                              process.env.DEBUG_API_ENDPOINT_GRAPHQL = 'https://encoura-graph.myoptions.org/graphql';
                              setBackendUri('https://encoura-graph.myoptions.org/graphql');
                            }}
                          />
                        }
                        label="PROD - are you really sure about this?"
                      />
                    </Stack>

                    <OutlinedInput
                      endAdornment={
                        <Button
                          onClick={() => {
                            if (textInput.current) {
                              process.env.DEBUG_API_ENDPOINT_GRAPHQL = textInput.current;
                              setBackendUri(textInput.current);
                              textInput.current = null;
                            }
                          }}
                          variant="contained"
                        >
                          set
                        </Button>
                      }
                      id="debug-menu-input"
                      onChange={e => {
                        textInput.current = e.target.value;
                      }}
                      placeholder={backendUri}
                    />
                  </>
                )}
                <Box mt={3}>
                  <Typography variant="h5">
                    {isImpersonating ? 'Educator Info' : 'User Info'}
                    {user ? ` - ${user?.firstName} ${user?.lastName}` : ''}
                  </Typography>
                  <Box>
                    <Stack>
                      <Typography variant="h6">contact_id:</Typography>
                      <Stack alignItems="center" direction="row">
                        <Typography sx={{ color: !contactId ? 'red' : undefined }} variant="body2">
                          {contactId || 'no contact_id'}
                        </Typography>
                        <Button
                          disabled={!contactId}
                          onClick={() => {
                            handleCopyToClipboard('contact_id', contactId || '');
                          }}
                          sx={{ fontSize: 10, minWidth: 0, px: 1, py: 0 }}
                        >
                          Copy
                        </Button>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Typography variant="h6">schoolId:</Typography>
                      <Stack alignItems="center" direction="row">
                        <Typography sx={{ color: !schoolId ? 'red' : undefined }} variant="body2">
                          {schoolId || 'no schoolId'}
                        </Typography>
                        <Button
                          disabled={!schoolId}
                          onClick={() => {
                            handleCopyToClipboard('schoolId', schoolId || '');
                          }}
                          sx={{ fontSize: 10, minWidth: 0, px: 1, py: 0 }}
                        >
                          Copy
                        </Button>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Typography sx={{}} variant="h6">
                        auth token:
                      </Typography>
                      <Stack alignItems="center" direction="row">
                        <Typography
                          sx={{
                            color: !token ? 'red' : undefined,
                          }}
                          variant="body2"
                        >
                          {authDisplay}
                        </Typography>
                        <Button
                          disabled={!token}
                          onClick={() => {
                            handleCopyToClipboard('auth token', token ? auth : '');
                          }}
                          sx={{ fontSize: 10, minWidth: 0, px: 1, py: 0 }}
                        >
                          Copy
                        </Button>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Typography sx={{}} variant="h6">
                        uid:
                      </Typography>
                      <Stack alignItems="center" direction="row">
                        <Typography
                          sx={{
                            color: !uid ? 'red' : undefined,
                          }}
                          variant="body2"
                        >
                          {uid || 'not logged in'}
                        </Typography>
                        <Button
                          disabled={!uid}
                          onClick={() => {
                            handleCopyToClipboard('uid', uid || '');
                          }}
                          sx={{ fontSize: 10, minWidth: 0, px: 1, py: 0 }}
                        >
                          Copy
                        </Button>
                      </Stack>
                    </Stack>
                    {isImpersonating && (
                      <Stack>
                        <Typography sx={{}} variant="h6">
                          Root user - {`${rootUser?.firstName} ${rootUser?.lastName}`}
                        </Typography>
                        <Stack alignItems="center" direction="row">
                          <Typography
                            sx={{
                              color: !uid ? 'red' : undefined,
                            }}
                            variant="body2"
                          >
                            {rootUser?.uid || 'not logged in'}
                          </Typography>
                          <Button
                            disabled={!uid}
                            onClick={() => {
                              handleCopyToClipboard('uid', rootUser?.uid || '');
                            }}
                            sx={{ fontSize: 10, minWidth: 0, px: 1, py: 0 }}
                          >
                            Copy
                          </Button>
                        </Stack>
                      </Stack>
                    )}
                  </Box>
                </Box>
                {isStudentDetailsPage && (
                  <Box mt={3}>
                    <Typography variant="h5">
                      Student Info
                      {studentInfo ? ` - ${studentInfo?.firstName} ${studentInfo?.lastName}` : ''}
                    </Typography>
                    <Box>
                      <Stack>
                        <Typography variant="h6">student_key:</Typography>
                        <Stack alignItems="center" direction="row">
                          <Typography sx={{ color: !studentInfo?.studentKey ? 'red' : undefined }} variant="body2">
                            {studentInfo?.studentKey || 'no student_key'}
                          </Typography>
                          <Button
                            disabled={!studentInfo?.studentKey}
                            onClick={() => {
                              handleCopyToClipboard('student_key', `${studentInfo?.studentKey || ''}` || '');
                            }}
                            sx={{ fontSize: 10, minWidth: 0, px: 1, py: 0 }}
                          >
                            Copy
                          </Button>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                )}
              </Stack>
              <Button
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  await router.push(ROUTES.INDEX);
                  handleClose();
                }}
                variant="contained"
              >
                Go home
              </Button>
            </Stack>
          </TabPanel>
          <TabPanel sx={{ height: '100%' }} value="2">
            <Grid2>
              <Typography variant="h4">Features</Typography>
              <Typography variant="body1">Enable/disable features on your local environment</Typography>
              <FormControlLabel
                control={<Switch checked={showSuperintendentUx} onChange={onChangeSuperintendentLocalFlag} sx={{ marginRight: '8px' }} />}
                label="Show Superintendent UX"
                sx={{ marginTop: '8px' }}
              />
            </Grid2>
            {showFeatureFlags && (
              <Grid2 sx={{ marginTop: '48px' }}>
                <Typography variant="h4">Feature Flags ({getEnvironment()})</Typography>
                <Typography color="red" variant="h6">
                  CHANGES HERE AFFECT THE FLAGS OF THE ENTIRE ENVIRONMENT, BE CAREFUL!
                </Typography>
                <FormControlLabel
                  control={<Switch checked={featureFlagShowSuperintendent} onChange={onChangeSuperintendentFeatureFlag} sx={{ marginRight: '8px' }} />}
                  label="Feature Flag - Superintendent UX"
                  sx={{ marginTop: '24px' }}
                />
              </Grid2>
            )}
          </TabPanel>
          <TabPanel sx={{ height: '100%' }} value="3">
            <Stack height="100%" justifyContent="space-between">
              <Stack>
                <Typography variant="h4">EIS</Typography>
                <Typography variant="h6">Controls related to EIS migration</Typography>
                <Divider style={{ margin: 8 }} />
                <Typography variant="body1">Overwrite BUILD_ENV environment variable</Typography>
                <li>Affects Guard component</li>
                <Typography>
                  Selected environment: <strong>{selectedEnvironment}</strong>
                </Typography>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedEnvironment === 'feature'}
                      onChange={() => {
                        localStorage.setItem(environmentLocalCacheKey, 'feature');
                        setSelectedEnvironment('feature');
                      }}
                    />
                  }
                  label="Feature"
                />
                <Stack>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedEnvironment === 'development'}
                        onChange={() => {
                          localStorage.setItem(environmentLocalCacheKey, 'development');
                          setSelectedEnvironment('development');
                        }}
                      />
                    }
                    label="Development"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedEnvironment === 'stage'}
                        onChange={() => {
                          localStorage.setItem(environmentLocalCacheKey, 'stage');
                          setSelectedEnvironment('stage');
                        }}
                      />
                    }
                    label="Stage"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedEnvironment === 'production'}
                        onChange={() => {
                          localStorage.setItem(environmentLocalCacheKey, 'production');
                          setSelectedEnvironment('production');
                        }}
                      />
                    }
                    label="Production"
                  />
                  <Button
                    onClick={() => {
                      localStorage.removeItem(environmentLocalCacheKey);
                      setSelectedEnvironment(ENV.BUILD_ENV || '');
                    }}
                  >
                    Clear selection
                  </Button>
                </Stack>
              </Stack>
              <Button
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  await router.push(ROUTES.EIS);
                  handleClose();
                }}
                variant="contained"
              >
                Go to EIS index page
              </Button>
            </Stack>
          </TabPanel>
        </TabContext>
      </Drawer>
      <Fab
        aria-describedby={id}
        onClick={handleClick}
        size="small"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        sx={{ bottom: 0, opacity: 0.5, position: 'fixed', right: 0 }}
      >
        <BugIcon />
      </Fab>
    </>
  );
};

export default DebugMenu;
