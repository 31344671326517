import { NamedGQLResult } from '@encoura/apollo-rest-utils';

export * from './auth';
export * from './base';
export * from './logger';
export * from './reports';
export * from './school';
export * from './schoolSelection';
export * from './students';
export * from './ums';
export * from './users';

export interface IPagingHeaders {
  headers: {
    totalCount: string;
    totalPages: string;
  };
}

export type IDataWithHeaders<T> =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends NamedGQLResult<infer TName, infer TData> ? { [k in TName]: TData } & IPagingHeaders : undefined;
