const NAMES = {
  APP: 'Encourage',
  COMPANY: 'Encoura, LLC',
  COOKIE: {
    DEV: 'tokenDev',
    PRD: 'token',
    STG: 'tokenStg',
  },
  IMPERSONATING: 'impersonatedUser',
  SCHOOL_ID: 'schoolId',
  TOKEN: 'token',
};

export default NAMES;
