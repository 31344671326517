import E4ECustomDimensions from '@actinc/dls/styles/themeEncourageE4E/E4ECustomDimensions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

import { HIGH_SCHOOL_REPORTS } from '~/constants/reports';
import TAG_IDS from '~/constants/tagIds';
import { getItemsMaxLenghtByKey } from '~/helpers/schoolSearch';
import useUserPermission from '~/hooks/useUserPermission';
import useWindowDimensions from '~/hooks/useWindowDimensions';

import DialogContainer from '../DialogContainer';

import ReportRedirect from './ReportRedirect';
import { LegendContainer, ReportCardsContainer } from './styles';

export interface IDialogProps {
  closeDialog: () => void;
  id: string;
  open: boolean;
}

export const EDUCATION_PARTNER_DIALOG_NAME = 'EducationPartnerHomeDialog';

const EducationPartnerDialog: React.FC<IDialogProps> = ({ closeDialog, id, open }: IDialogProps): React.ReactElement | null => {
  const shouldOpenDialog = open;
  const getUserPermissions = useUserPermission();
  const windowDimensions = useWindowDimensions();

  const parseUserReports = (reports: Array<string>): Array<Record<string, string>> => {
    const parsedUserReports = reports
      .filter(report => report !== 'reportNATIONAL')
      .map(reportName => {
        return HIGH_SCHOOL_REPORTS[reportName];
      })
      .sort((reportA, reportB) => {
        if (!reportA.default || !reportB.default) return 0;
        if (reportA.default === reportB.default) return 0;
        if (reportA.default < reportB.default) return -1;
        return 1;
      });
    return parsedUserReports;
  };

  /**
   * In order to avoid multiple references which can cause problems in the application I'm using a function that will
   * take the larger report name and calculate the span size based on a scale 8.87 This will give us the number of rows
   * that the each span should take. This is necessary to align the reports on the same row as UX wants
   */
  const calculateLineProportion = (reportNumber: number, dialogSize: number, maxReportName: number): number => {
    // First we fetch the size of the actual dialog
    const dialogWidth = windowDimensions?.windowWidth && windowDimensions.windowWidth <= dialogSize + 64 ? windowDimensions.windowWidth - 64 : dialogSize;
    // Now we catch the size of the grid that wraps each report divinding it by the number of reports + 1 since we are not considering the National Report
    // The +2 refers of the number of gutters between reports which is 24px by default
    const reportGrid = (dialogWidth - (reportNumber + 2) * 24) / (reportNumber + 1);
    // Generates the size of the span based on the report name with the most characters * a defined proportion
    const textSizeProportion = maxReportName * 8.87;

    // Now we get how many lines the span will fill and send it back
    if (textSizeProportion > reportGrid) return Math.round(textSizeProportion / (reportGrid - 32)) + 1;
    return Math.floor(textSizeProportion / (reportGrid - 32)) + 1;
  };

  const userReports = getUserPermissions.permissions;
  const parsedUserReports = parseUserReports(userReports);

  const nationalReport = HIGH_SCHOOL_REPORTS.reportNATIONAL;
  const maxFilteredReportName = getItemsMaxLenghtByKey(parsedUserReports, 'default');
  const maxReportName = maxFilteredReportName && maxFilteredReportName > nationalReport.default.length ? maxFilteredReportName : nationalReport.default.length;

  const isLargeDialog = parsedUserReports.length >= 2;

  const xsGridSize = parsedUserReports.length >= 3 ? 6 : 12 / (parsedUserReports.length + 1);
  const smGridSize = parsedUserReports.length >= 3 ? 3 : 12 / (parsedUserReports.length + 1);

  const dialogSize = isLargeDialog ? E4ECustomDimensions.widths.largeDialog : E4ECustomDimensions.widths.mediumDialog;
  const reportNameSize = calculateLineProportion(parsedUserReports.length, dialogSize, maxReportName);

  return (
    <DialogContainer
      hideButtons
      noGap
      onClose={closeDialog}
      open={shouldOpenDialog}
      size={isLargeDialog ? 'large' : 'medium'}
      tagIds={{
        CLOSE_BUTTON: TAG_IDS.DIALOGS.EDUCATION_PARTNER.CLOSE_BUTTON,
        DIALOG_CONTAINER: id,
        DIALOG_CONTENT: TAG_IDS.DIALOGS.EDUCATION_PARTNER.CONTENT,
      }}
      title="Reports"
    >
      <Grid container direction="column">
        <LegendContainer item xs={12}>
          <Typography component="span" variant="body1Strong">
            Which report would you like to see?
          </Typography>
        </LegendContainer>

        <ReportCardsContainer columnSpacing="24px" container item rowSpacing="16px">
          {parsedUserReports.map(report => {
            return (
              <ReportRedirect
                close={closeDialog}
                isLargeDialog={isLargeDialog}
                key={report.default}
                report={report}
                reportNameSize={reportNameSize}
                sm={smGridSize}
                xs={xsGridSize}
              />
            );
          })}
          <ReportRedirect
            close={closeDialog}
            isLargeDialog={isLargeDialog}
            key="national-report"
            report={nationalReport}
            reportNameSize={reportNameSize}
            sm={smGridSize}
            xs={xsGridSize}
          />
        </ReportCardsContainer>
      </Grid>
    </DialogContainer>
  );
};

export default EducationPartnerDialog;
