import first from 'lodash/first';
import { useMemo } from 'react';

import {
  EncouraStudentPaginationSortOption,
  OrderDirection,
  useEducatorStudentListQuery,
} from '~/__generated__/graphql';
import { EDUCATION_PARTNER_HS_ID, HIGH_SCHOOL_REPORTS, HIGH_SCHOOL_REPORTS_CODES } from '~/constants/reports';
import { Role } from '~/constants/roles';
import { HS_REGEX, ROUTES, ROUTES_REGEX } from '~/constants/routes';
import USER_TYPES from '~/constants/userTypes';
import { WalkMeSetUserData } from '~/helpers/walkme/WalkMeInsights';
import useDebugMenu from '~/hooks/useDebugMenu';

import useActiveUser from '../useActiveUser';
import useDetailedPermissions from '../useDetailedPermissions';
import useEducatorInviteCode from '../useEducatorInviteCode';
import useQueryParam from '../useQueryParam';

const useLandingPageRedirect = (hsId: string): { url: string; loading: boolean } => {
  const activeUser = useActiveUser();
  const getDetailedPermissions = useDetailedPermissions();
  const returnUrl = useQueryParam('returnUrl');
  const { superintendentFlag } = useDebugMenu();

  WalkMeSetUserData({ role: undefined, uid: activeUser?.uid as string });

  const { data: educatorData, loading: loadingEducatorData } = useEducatorInviteCode(hsId);
  const educatorInviteCodes = educatorData?.getEducatorData?.invite_codes;
  const contactId = educatorData?.getEducatorData?.contact_id;
  const inviteCode =
    educatorInviteCodes?.find(inviteCodeObject => inviteCodeObject.school_id === hsId)?.invite_code ?? '';

  const { data: educatorStudentList, loading: loadingEducatorStudentList } = useEducatorStudentListQuery({
    context: { apiName: 'federatedGraph' },
    skip: !inviteCode,
    variables: {
      contactId,
      filters: {
        mostRecentDataSource: [],
        name: '',
      },
      graduationYears: '2025,2026,2027,2028,2029,2030,UNKNOWN',
      inviteCode,
      pagination: {
        limit: 10,
        offset: 0,
        sort: EncouraStudentPaginationSortOption.GraduationYear,
        sortDirection: OrderDirection.Asc,
      },
    },
  });

  // eslint-disable-next-line promise/prefer-await-to-then
  getDetailedPermissions.refetch().catch(er => {
    // eslint-disable-next-line no-console
    console.error(er);
  });

  const detailedPermissions = getDetailedPermissions.data?.detailedPermissions;

  const userHsIds = detailedPermissions?.map(permission => permission.organization?.externalId);
  const isSuperintendent = useMemo(
    () =>
      superintendentFlag?.enabled && detailedPermissions?.some(item => item.organization?.role === Role.SUPERINTENDENT),
    [superintendentFlag, detailedPermissions],
  );

  const loading = getDetailedPermissions.loading || !activeUser || loadingEducatorData || loadingEducatorStudentList;

  const hasAnyStudent = (educatorStudentList?.encouraEducator?.studentsPaginated?.totalCount || 0) > 0;

  if (returnUrl && !loading) {
    const returnUrlWithShoolIdCompare = `${returnUrl as string}/${HS_REGEX}`.replace(/\//g, '\\/');
    let routeWithShoolIdRegex = false;

    Object.entries(ROUTES_REGEX).forEach(route => {
      if (route[1] === returnUrlWithShoolIdCompare) routeWithShoolIdRegex = true;
    });

    if (hsId === EDUCATION_PARTNER_HS_ID && activeUser?.type !== USER_TYPES.LDAP) {
      if (activeUser?.uid) {
        WalkMeSetUserData({ role: 'partner', uid: activeUser.uid });
      }
    }

    return {
      loading: false,
      url: routeWithShoolIdRegex ? `${returnUrl as string}/${hsId}` : (returnUrl as string),
    };
  }

  if (superintendentFlag?.loading) {
    return { loading: true, url: '' };
  }

  if (hsId === EDUCATION_PARTNER_HS_ID && detailedPermissions && detailedPermissions.length > 0) {
    const partnerOrgUserPermissions = detailedPermissions.filter(
      schoolPermission => schoolPermission.organization?.externalId === EDUCATION_PARTNER_HS_ID,
    );
    const userPermissions = partnerOrgUserPermissions.map(({ permissions }) => permissions);
    const partnerSpecificReport: string[] = [];

    userPermissions.forEach(
      permissions =>
        permissions &&
        Object.entries(permissions).forEach(([key, value]) => {
          if (value && HIGH_SCHOOL_REPORTS_CODES.includes(key)) {
            partnerSpecificReport.push(key);
          }
        }),
    );

    if (activeUser?.uid) {
      WalkMeSetUserData({ role: 'partner', uid: activeUser.uid });
    }

    let firstReportPage = ROUTES.REPORTS_NATIONAL_REPORT;
    if (partnerSpecificReport.length > 0) {
      const specificReport = first(partnerSpecificReport.sort());
      if (specificReport) {
        firstReportPage = `${HIGH_SCHOOL_REPORTS[specificReport].route}/${hsId}`;
      }
    }

    return {
      loading: false,
      url: firstReportPage,
    };
  }

  if (activeUser?.type === USER_TYPES.LDAP) {
    WalkMeSetUserData({ role: 'ldap_user', uid: activeUser?.uid });
    return {
      loading: false,
      url: `${ROUTES.ADMINISTRATION}/${hsId}`,
    };
  }

  let url;

  if (isSuperintendent) {
    url = `${ROUTES.REPORT_DASHBOARD}/${hsId}`;
  } else if (hasAnyStudent) {
    url = `${ROUTES.MY_STUDENTS}/${hsId}`;
  } else {
    url = `${ROUTES.LESSON_PLANS}/${hsId}`;
  }

  if ((userHsIds && userHsIds.length < 1) || isSuperintendent) {
    return {
      loading: loading || superintendentFlag.loading,
      url,
    };
  }

  if (userHsIds && userHsIds.length >= 1) {
    const hsIdIndex = userHsIds.indexOf(hsId, 0);
    const permissions = detailedPermissions && detailedPermissions[hsIdIndex].permissions;
    if (permissions?.highSchoolAdmin) {
      return {
        loading,
        url: `${ROUTES.ADMINISTRATION}/${hsId}`,
      };
    }
  }

  return {
    loading,
    url,
  };
};

export default useLandingPageRedirect;
