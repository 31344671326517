import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DISTRICT_SUPERINTENDENT_USER_EXPERIENCE } from '~/constants/featureFlags';
import getEnvironment from '~/helpers/getEnvironment';
import { DebugFlagContext, Flag, IDebugContextData, IDebugFlag } from '~/hooks/useDebugMenu/context';
import useFeatureFlags from '~/hooks/useFeatureFlags';

interface IChildrenProps {
  children: ReactNode;
}

export function DebugFlagsProvider({ children }: IChildrenProps): ReactNode {
  const [superintendentFlag, setSuperintendentFlag] = useState<IDebugFlag>({ enabled: getEnvironment() !== 'production', loading: true });

  const featureFlags = useFeatureFlags();
  const { data: superintendentFlagEnabled, loading: loadingSuperintendentFlag } = featureFlags(DISTRICT_SUPERINTENDENT_USER_EXPERIENCE);

  useEffect(() => {
    if (getEnvironment() === 'production') {
      setSuperintendentFlag({ enabled: superintendentFlagEnabled, loading: false });
      return;
    }
    const localFlag = global?.window?.localStorage.getItem(DISTRICT_SUPERINTENDENT_USER_EXPERIENCE);

    const enabled = localFlag ? localFlag === 'true' : superintendentFlagEnabled;
    setSuperintendentFlag({ enabled, loading: false });
  }, [loadingSuperintendentFlag, superintendentFlagEnabled]);

  const setFlag = useCallback((flag: Flag, value: boolean) => {
    if (getEnvironment() === 'production') return;
    global?.window?.localStorage.setItem(flag, value.toString());

    if (flag === DISTRICT_SUPERINTENDENT_USER_EXPERIENCE) {
      setSuperintendentFlag({ enabled: value, loading: false });
    }
  }, []);

  const contextValue = useMemo(() => ({ setFlag, superintendentFlag }), [setFlag, superintendentFlag]);

  return <DebugFlagContext.Provider value={contextValue}>{children}</DebugFlagContext.Provider>;
}

const useDebugMenu = (): IDebugContextData => {
  const context = useContext(DebugFlagContext);

  if (!context) {
    throw new Error('useDebugMenu must be used within a DebugFlagsProvider');
  }

  return context;
};

export default useDebugMenu;
