import { operations, paths } from '~/constants/__generatedSwaggerTypes';

export type SecondarySchoolUsersResponse =
  operations['getUserSecondarySchools']['responses'][200]['content']['application/json'];

export type SecondarySchoolUsersList =
  operations['getUserSecondarySchools']['responses'][200]['content']['application/json']['userList'];

export type SecondarySchoolUserOrg = NonNullable<SecondarySchoolUsersList>[number];

export type UserAccount = operations['updateUser']['responses'][200]['content']['application/json'];

export type UserHS = operations['getUserHS']['responses'][200]['content']['application/json'];

export type EncourageUser = Required<
  operations['reportUsers']['responses'][200]['content']['application/json']
>['users'][number];

export enum UserRoles {
  coach = 'coach',
  counselor = 'counselor',
  principal = 'principal',
  other = 'other',
  teacher = 'teacher',
  teaching_assistant = 'teaching_assistant',
  superintendent = 'superintendent',
}

export const MappedUserRoles = {
  [UserRoles.coach]: 'Coach',
  [UserRoles.counselor]: 'Counselor',
  [UserRoles.principal]: 'Principal',
  [UserRoles.other]: 'Other',
  [UserRoles.teacher]: 'Teacher',
  [UserRoles.teaching_assistant]: 'Teaching Assistant',
  [UserRoles.superintendent]: 'Superintendent',
};

export interface IUserSchool {
  hsId: string;
  name: string;
  role: UserRoles;
}

export interface IWalkmeUserData {
  role?: string | undefined;
  uid: string;
}

export interface IDetailedPermissions {
  detailedPermissions: paths['/detailed-permissions']['get']['responses']['200']['content']['application/json'];
}

export type WalkmeUserData = operations['getWalkmeUserData']['responses']['200']['content']['application/json'];
