import { FC, useState } from 'react';

import { EncouraSecondarySchool, useEncouraSecondarySchoolListQuery } from '~/__generated__/graphql';
import { LDAPSearchText } from '~/constants/schoolSearch';

import SchoolSearch from '../SchoolSearch';

interface IProps {
  closeDialog?: () => void;
}

const LdapSchoolSearch: FC<IProps> = props => {
  const { closeDialog } = props;

  const [searchQuery, setSearchQuery] = useState('');

  const { data, loading } = useEncouraSecondarySchoolListQuery({
    context: { apiName: 'federatedGraph' },
    errorPolicy: 'all',
    skip: !searchQuery,
    variables: {
      limit: 10,
      offset: 0,
      search: searchQuery.toString(),
    },
  });

  const dataState = loading ? 'loading' : 'loaded';
  const schools: EncouraSecondarySchool[] = !loading && data ? (data?.encouraSecondarySchoolList as EncouraSecondarySchool[]) : [];

  return (
    <SchoolSearch
      closeDialog={closeDialog}
      items={schools}
      onChangeSearchText={setSearchQuery}
      searchText={searchQuery}
      state={dataState}
      texts={LDAPSearchText}
      userIsLdap
    />
  );
};

export default LdapSchoolSearch;
