import { gql } from '@apollo/client';
import { wrapRestMutation } from '@encoura/apollo-rest-utils';

import REST from '~/constants/rest';
import { UseFeatureFlagResponse } from '~/hooks/useSetFeatureFlag/types';

export const PUT_FEATURE_FLAGS_MUTATION = gql`
  mutation PutFeatureFlagMutation($input: Input!) {
    featureFlags(input: $input) {
      key
      value
    }
  }
`;

const useSetFeatureFlag = (): UseFeatureFlagResponse => {
  const [setFeatureFlag, { loading, error, data }] = wrapRestMutation<'setFeatureFlag'>()(PUT_FEATURE_FLAGS_MUTATION, {
    endpoint: REST.PUT.FEATURE_FLAGS,
  });

  return [setFeatureFlag, { data, error, loading }];
};
export default useSetFeatureFlag;
