import CircularProgress from '@mui/material/CircularProgress';
import { DialogProps } from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { isString } from 'lodash';
import { CSSProperties, FC, MouseEvent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import getIcon from '~/helpers/getIcon';
import useWindowDimensions from '~/hooks/useWindowDimensions';

import { CancelButton, CloseIconButton, ConfirmButton, DialogActionsStyled, DialogContentStyled, DialogStyled, DialogTitleStyled } from './styles';

export const SizesMapper: Record<SizeEnum, string> = {
  large: '872px',
  medium: '648px',
  small: '424px',
};

const Close = getIcon('general.close');

type SizeEnum = 'small' | 'medium' | 'large';

type ButtonPosition = 'left' | 'right' | 'center' | 'fullwidth';

const ButtonPositionMapper: Record<ButtonPosition, string> = {
  center: 'center',
  fullwidth: 'unset',
  left: 'flex-start',
  right: 'flex-end',
};

interface ITagId {
  CANCEL_BUTTON?: string;
  CLOSE_BUTTON?: string;
  CONFIRM_BUTTON?: string;
  DIALOG_ACTIONS?: string;
  DIALOG_CONTAINER?: string;
  DIALOG_CONTENT?: string;
}

interface IProps extends Pick<DialogProps, 'PaperProps'> {
  buttonPosition?: ButtonPosition;
  children: ReactNode;
  closeButtonName?: string;
  confirmButtonIcon?: ReactNode;
  confirmButtonName?: string;
  contentMarginTop?: string;
  dialogActionsProps?: CSSProperties;
  disableConfirmButton?: boolean;
  fullscreen?: boolean;
  hideButtons?: boolean;
  hideCancelButton?: boolean;
  hideCloseButton?: boolean;
  hideLoadingSpinner?: boolean;
  loading?: boolean;
  lowerTopMargin?: boolean;
  noButtonLabelWrap?: boolean;
  noGap?: boolean;
  onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
  onCloseClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onConfirm?: (event: MouseEvent<HTMLButtonElement>) => Promise<void> | void;
  open: boolean;
  size?: SizeEnum;
  sx?: SxProps;
  tagIds?: ITagId;
  title?: string | ReactNode;
  titleIcon?: ReactNode;
}

const DialogContainer: FC<IProps> = ({
  buttonPosition = 'right',
  children,
  closeButtonName = 'Cancel',
  confirmButtonIcon = null,
  confirmButtonName,
  contentMarginTop,
  dialogActionsProps,
  disableConfirmButton = false,
  fullscreen = false,
  hideButtons = false,
  hideCancelButton = false,
  hideCloseButton = false,
  hideLoadingSpinner = false,
  loading = false,
  lowerTopMargin = false,
  noButtonLabelWrap = false,
  noGap = false,
  onConfirm,
  onClose,
  onCloseClick,
  open,
  PaperProps,
  size = 'medium',
  sx,
  tagIds = {},
  title = '\u00A0',
  titleIcon,
}: IProps): ReactElement => {
  const paperRef = useRef(null);
  const [dialogHeight, setDialogHeight] = useState<number>(0);
  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    const element = paperRef.current as unknown as HTMLDivElement;

    setDialogHeight(open ? element?.offsetHeight : 0);
  }, [open, loading]);

  return (
    <DialogStyled
      $dialogHeight={dialogHeight}
      $noGap={!!noGap}
      $screenHeight={windowDimensions?.windowHeight || null}
      disablePortal
      id={tagIds?.DIALOG_CONTAINER}
      onClose={onClose}
      open={open}
      PaperProps={{
        ...(PaperProps ?? {}),
        ref: paperRef,
        sx: {
          maxHeight: '100%',
          width: SizesMapper[size],
          ...(PaperProps?.sx ?? {}),
          ...(lowerTopMargin && { marginTop: '-32px !important' }),
          ...(fullscreen && {
            borderRadius: '0px !important',
            height: '100vh',
            marginTop: '-64px !important',
            position: 'absolute',
            width: '100vw',
          }),
        },
      }}
      sx={{
        ...sx,
        ...(fullscreen && {
          margin: '0! important',
        }),
      }}
      transitionDuration={{
        exit: 0,
      }}
    >
      <DialogTitleStyled>
        <Grid alignItems="center" display="flex" flexDirection="row" gap={titleIcon ? 1 : 0} item>
          <Grid item xs>
            {titleIcon}
          </Grid>

          {isString(title) ? (
            <Typography component="span" variant="h4">
              {title}
            </Typography>
          ) : (
            title
          )}
        </Grid>

        {!hideCloseButton && (
          <CloseIconButton color="secondary" id={tagIds?.CLOSE_BUTTON} onClick={onCloseClick || onClose} padding="small" size="medium">
            <Close />
          </CloseIconButton>
        )}
      </DialogTitleStyled>

      <DialogContentStyled id={tagIds?.DIALOG_CONTENT} sx={fullscreen ? { marginTop: contentMarginTop ?? { contentMarginTop }, width: '100%' } : {}}>
        {children}
      </DialogContentStyled>

      {!hideButtons && (
        <DialogActionsStyled id={tagIds?.DIALOG_ACTIONS} sx={{ ...dialogActionsProps, alignSelf: ButtonPositionMapper[`${buttonPosition}`] }}>
          {!hideCancelButton && (
            <CancelButton
              $noButtonLabelWrap={!!noButtonLabelWrap}
              color="primary"
              disabled={loading}
              fullWidth={buttonPosition === 'fullwidth'}
              id={tagIds?.CANCEL_BUTTON}
              name={closeButtonName}
              onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                if (onClose) {
                  onClose(event);
                }
              }}
              size="large"
              variant="outlined"
            >
              {closeButtonName}
            </CancelButton>
          )}

          {onConfirm && (
            <ConfirmButton
              $noButtonLabelWrap={!!noButtonLabelWrap}
              color="primary"
              disabled={disableConfirmButton || loading}
              fullWidth={buttonPosition === 'fullwidth'}
              id={tagIds?.CONFIRM_BUTTON}
              name={confirmButtonName}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={(event: MouseEvent<HTMLButtonElement>): Promise<void> | void => onConfirm(event)}
              size="large"
              variant="contained"
            >
              {loading && !hideLoadingSpinner ? (
                <CircularProgress color="primary" size="1.5em" />
              ) : (
                <>
                  {confirmButtonIcon}
                  {confirmButtonName}
                </>
              )}
            </ConfirmButton>
          )}
        </DialogActionsStyled>
      )}
    </DialogStyled>
  );
};

DialogContainer.defaultProps = {
  buttonPosition: 'right',
  closeButtonName: 'Cancel',
  confirmButtonIcon: null,
  confirmButtonName: undefined,
  contentMarginTop: '52px',
  dialogActionsProps: undefined,
  disableConfirmButton: false,
  fullscreen: false,
  hideButtons: false,
  hideCancelButton: false,
  hideCloseButton: false,
  hideLoadingSpinner: false,
  loading: false,
  lowerTopMargin: false,
  noButtonLabelWrap: false,
  noGap: false,
  onCloseClick: undefined,
  onConfirm: undefined,
  size: 'medium',
  tagIds: {},
  title: '\u00A0',
  titleIcon: undefined,
};

export default DialogContainer;
