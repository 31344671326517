import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

import { environmentLocalCacheKey } from '~/components/DebugMenu';
import ENV from '~/constants/env';
import { BuildEnv } from '~/types';

const Auth0ProviderComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const debugEnv = typeof window !== 'undefined' ? localStorage.getItem(environmentLocalCacheKey) : '';

  if (ENV.BUILD_ENV === BuildEnv.Feature || debugEnv === BuildEnv.Feature) {
    return children;
  }

  return (
    <Auth0Provider
      authorizationParams={{
        audience: String(ENV.AUTH0_AUDIENCE),
        scope: 'offline_access openid profile email',
      }}
      clientId={String(ENV.AUTH0_CLIENT_ID)}
      domain={String(ENV.AUTH0_DOMAIN)}
      useRefreshTokens
      useRefreshTokensFallback
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderComponent;
