import { CookieSetOptions } from 'universal-cookie';

import ENV from '~/constants/env';
import NAMES from '~/constants/names';
import NEXT from '~/constants/next';
import { BuildEnv } from '~/types';

export interface IAuthTokenInfo {
  value: string;
}

export type CookieAuthTokenInfo = Record<string, IAuthTokenInfo>;

export const cookieTokenMapper: Record<string, string> = {
  [BuildEnv.Development]: NAMES.COOKIE.DEV,
  [BuildEnv.Feature]: NAMES.COOKIE.DEV,
  [BuildEnv.Production]: NAMES.COOKIE.PRD,
  [BuildEnv.Staging]: NAMES.COOKIE.STG,
};

export const TOKEN_COOKIE_NAME = String(
  ENV.BUILD_ENV ? cookieTokenMapper[ENV.BUILD_ENV] || NAMES.COOKIE.DEV : NAMES.COOKIE.DEV,
);

export const buildCookieDomain = (pathname: string): { domain: string } => {
  if (!pathname) {
    return { domain: '' };
  }

  const splits = pathname.split('.');

  let domain;

  if (splits.length <= 1) {
    domain = splits[0];
  } else if (splits.length === 2) {
    domain = pathname;
  } else {
    domain = splits.slice(-2).join('.');
  }

  return { domain };
};

export const cookieConfig: CookieSetOptions = {
  path: '/',
  ...buildCookieDomain(NEXT.IS_CLIENT ? window.location.hostname : ''),
};
