import { SchoolInfoQuery, useSchoolInfoQuery } from '~/__generated__/graphql';

import useSchoolId from '~/hooks/useSchoolId';

interface ISchoolInfo {
  data: SchoolInfoQuery | undefined;
  loading: boolean;
}

export const useSchoolInfo = (): ISchoolInfo => {
  const schoolId = useSchoolId();

  const { data, loading } = useSchoolInfoQuery({
    context: { apiName: 'federatedGraph' },
    errorPolicy: 'all',
    skip: !schoolId || schoolId === 'Mentor',
    variables: {
      secondarySchoolId: schoolId,
    },
  });

  return { data, loading };
};

export default useSchoolInfo;
