// eslint-disable-next-line no-restricted-imports
import { IconButtonProps } from '@mui/material/IconButton';
import { ReactElement } from 'react';

import { CustomIconButton } from './styles';

export interface IIconButtonProps extends IconButtonProps {
  disableHover?: boolean;
  padding?: 'small' | 'medium' | 'none';
}

const IconButton = (iIconButtonProps: IIconButtonProps): ReactElement => {
  const { disableHover, padding = 'small', ...props } = iIconButtonProps;
  return <CustomIconButton disableHover={disableHover} padding={padding} {...props} />;
};

export default IconButton;
